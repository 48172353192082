.off-canvas {
	z-index: 50000;
	width: 100%;
	transform: translateX(-100%);
	background-color: get-color('black');

	&.is-open {
		transform: translateX(0);
	}

	&__inner {
		padding: 38px;

		@include breakpoint(large) {
			padding: 35px 53px;
		}
	}

	&__main {
		margin: 84px auto 0;
		max-width: 1260px;
		width: 100%;

		@include breakpoint(small only) {
			margin-top: 81px;
		}
	}

	&__header {
		position: relative;
		margin-bottom: 60px;
	}

	&__close {
		position: absolute;
		top: 0;
		left: 0;
		width: 21px;
		height: 21px;
		background: url('img/close-thin-white.svg') no-repeat;
		background-size: 21px 21px;
		cursor: pointer;
		text-indent: -12345px;

		&:focus,
		&:active {
			outline: none;
		}
	}

	&__logo {
		width: 151px;
		margin: 0 auto;
	}

	&__nav-primary {
		margin-bottom: 65px;
		margin-top: 25px;

		@include breakpoint(small only) {
			border-bottom: 1px solid get-color('red');
			margin-bottom: 21px;
		}

		a {
			transition: color 0.3s ease-in-out;
			color: get-color('white');

			&:hover,
			&:active {
				color: get-color('red');
			}
		}

		> ul {
			margin: 0;
			list-style-type: none;

			> li {
				margin-bottom: 38px;

				> a {
					@include h2;
				}

				&.open > a {
					color: get-color('red');
				}

				> ul {
					margin: 8px 0 30px 35px;
					list-style-type: none;
					display: none;

					@include breakpoint(small only) {
						margin-left: 0;
					}

					> li {
						margin-top: 21px;

						@include breakpoint(small only) {
							line-height: 1;
							margin-bottom: 20px;
						}

						> a {
							@include h2;
							font-size: 23px;
						}
					}
				}
			}
		}
	}

	&__offer {
		margin-bottom: 33px;
		padding: 0 1% 0 3%;

		&-inner {
			background-color: get-color('white');
			text-align: center;

			h3 {
				@include h2();
				margin-bottom: 23px;

				a {
					color: get-color('black');
				}
			}
		}

		&-content {
			padding: 35px 0 20px 0;
		}

		&-price {
			@include price();
			margin-bottom: 34px;
		}

		&-cta {
			.button {
				margin-right: 15px;
				margin-left: 15px;
			}
		}
	}

	&__nav-header {
		display: none;

		@media screen and (max-width: $offcanvas-breakpoint) {
			display: block;
		}

		ul {
			margin: 0 auto;
			list-style-type: none;
		}

		a {
			@include subheading();
			color: get-color('white');
			transition: all 0.3s ease-in-out;

			&:hover,
			&:focus,
			&:active {
				color: get-color('red');
			}
		}

		li {
			@include breakpoint(small only) {
				margin-bottom: 4px;
			}
		}
	}

	.footer__newsletter {
		max-width: none;
	}
}
