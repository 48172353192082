@mixin slider-caption {
	font-family: $primary-font;
	font-size: 37px;
	line-height: 44px;

	@include breakpoint(medium) {
		font-size: 46px;
		line-height: 50px;
	}
}

@mixin h1 {
	font-family: $primary-font;
	font-size: 34px;
	line-height: 39px;

	@include breakpoint(medium) {
		font-size: 40px;
		line-height: 45px;
	}
}


@mixin h2 {
	font-family: $primary-font;
	font-size: 30px;
	line-height: 32px;
}


@mixin subheading {
	font-family: $secondary-font;
	font-size: 12px;
	line-height: 19px;
	font-weight: 800;
	text-transform: uppercase;
	color: get-color('mid-grey');
	letter-spacing: 0.10em;
}


@mixin price {
	font-family: $secondary-font;
	font-style: italic;
	font-size: 20px;
	line-height: 23px;
	color: get-color('red');
}


@mixin burger-menu-sub-nav {
	font-family: $secondary-font;
	font-size: 23px;
	line-height: 27px;
	color: get-color('black');
}


@mixin body-copy {
	font-family: $secondary-font;
	font-size: 14px;
	line-height: 22px;
	color: get-color('black');
}


@mixin bullet-list {
	font-family: $secondary-font;
	font-size: 13px;
	line-height: 27px;
	color: get-color('black');
	font-weight: 800;
}


@mixin menu-list {
	font-family: $secondary-font;
	font-size: 13px;
	line-height: 27px;
	color: get-color('black');
}


@mixin terms-conditions {
	font-family: $secondary-font;
	font-size: 11px;
	line-height: 17px;
	color: get-color('black');
}


@mixin smallest {
	font-family: $secondary-font;
	font-size: 11px;
	line-height: 14px;
	text-transform: uppercase;
	color: get-color('black');
}
