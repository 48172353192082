@charset 'utf-8';

$offcanvas-breakpoint: 970px;

$foundation-palette: (
	primary: #535A52, // very dark grey/geeen
	secondary: #767676, // default
	success: #3adb76, // default
	warning: #ffae00, // default
	alert: #cc4b37, // default
	'black': #2c2a29,
	'white': #fff,
	'mid-grey': #898989,
	'pale-grey': #EBE6DE,
	'grey': #707070,
	'red': #D22630,
	'light-grey': #f0f0f0,
	'gold': #ad7e00,
	'claret': #810000
);

$body-background: #fff;

$primary-font: 'DM Serif Display', serif;
$secondary-font: 'DM Sans', sans-serif;
