.booking {
  z-index: 50000;
  padding:  5% 10%;
  background: get-color('black');

  @include breakpoint(small only) {
    padding: 30px 20px;
  }

  &__close {
    position: absolute;
    z-index: 10;
    top: 55px;
    left: 50px;
    padding-left: 25px;
    height: 15px;
    color: get-color('white');
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;

    @include breakpoint(small only) {
      top: 15px;
      left: 20px;
    }

    &:focus {
      outline: none;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 23px;
      height: 23px;
    }
  }

  &__wrap {
    width: 100%;
    max-width: 660px;
    margin: 0 auto;
  }

  &__tabs {
    margin-bottom: 30px;


    .tabs {
      border: none;
      background: transparent;
      margin-left: -0.9375rem;
      margin-right: -0.9375rem;

      &-title {
        text-align: center;
        min-width: max-content;
        margin-bottom: 10px;

        a {
          padding: 7px 20px;
          text-transform: uppercase;
          transition: background-color 0.3s ease-in-out;
          border: 1px solid get-color('white');
          @include subheading();
          color: get-color('white');
          font-size: 11px;

          &:hover,
          &:focus {
            background: get-color('red');
            border-color: get-color('red');
            color: get-color('white');
          }
        }

        &.is-active {
          a {
            background: get-color('red');
            border-color: get-color('red');

          }
        }
      }
    }

    &-content {

      .tabs-panel {
        padding: 0 !important;
      }

      &-room {

        form {
          position: relative;

          div.medium-4 {
            text-align: center;

            .field-name {
              color: get-color('black');
              font-size: 13px;
              font-weight: 600;
              height: fit-content;
              padding-top: 16px;
            }

            .arrival-date,
            .depart-date {
              background-color: #fff;

              input {
                background: url('img/chevron-down-grey.svg') no-repeat;
                background-position: center right 10px;
                background-size: 12px 6px;
                color: get-color('mid-grey');
                font-size: 54px;
                line-height: 1;
                font-family: $primary-font;
                border: none;
                text-align: center;
                width: 100%;
                cursor: pointer;
                margin: 20px 0;
                padding: 30px 0;

                &:focus {
                  outline: none;
                }

                &::placeholder {
                  color: inherit;
                }
              }

              label {
                color: get-color('black');
                font-size: 13px;
                line-height: 16px;
                font-weight: 600;

                &.check-out-day,
                &.check-in-day {
                  display: block;
                }
              }
            }

            .adults-select {
              margin-top: 17px;

              .selectric {
                background: url('img/chevron-down-grey.svg') no-repeat center right 10px;
                background-size: 12px 6px;
                border: none;

                span.label {
                  color: get-color('mid-grey');
                  font-size: 54px;
                  line-height: 1;
                  font-family: $primary-font;
                  border: none;
                  text-align: center;
                  width: 100%;
                  height: 100%;
                  margin: 6px 0;
                }

                b.button {
                  display: none;
                }

                &-items {
                  border-color: get-color('black') !important;
                }

                &-scroll {

                  ul {

                    li {
                      background-color: #fff;
                      color: get-color('primary');
                      font-weight: 500;
                      font-size: 16px;
                      cursor: pointer;
                      transition: background-color 0.3s ease-in-out;

                      &:hover {
                        background-color: get-color('red');
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &-submit {
          .button {
            width: 100%;
            margin: 0 auto 35px;

            &:hover,
            &:active,
            &:focus {
              border-color: get-color('white');
            }
          }
        }

        &-why-book {
          text-align: left;

          .accordionButton {
            position: relative;
            cursor: pointer;
            font-size: 13px;
            font-weight: 600;
            text-transform: uppercase;
            color: get-color('red');
            letter-spacing: 0.1em;

            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              width: 20px;
              height: 20px;
              transform: rotate(0deg);
              transition: all 0.3s ease-in-out;
              background: url('img/close-thin-black.svg');
              background-size: 20px 20px;
            }

            &.on::after {
              transform: rotate(45deg);
            }
          }

          .accordionContent {
            padding-top: 15px;
            color: get-color('red');
            font-size: 15px;
            line-height: 19px;
          }
        }
      }

      &-table {
        position: relative;

        iframe {
          margin-bottom: 80px;
        }
      }

      &-dining-domes {
        position: relative;
      }

      p {
        @include body-copy();
        color: get-color('white');
      }
    }
  }

  &__field {
    background-color: #fff;
    box-shadow: 0 8px 10px rgba(get-color('red'), 0.4);
    padding: 20px 0;
    margin-bottom: 20px;

    @include breakpoint(medium) {
      margin-bottom: 50px;
    }
  }
}
