.slider {
  position: relative;
  text-align: center;

  .slider__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.3;
    z-index: 2;
  }

  .slider__inner {
    width: 100vw;
    height: 75vh;
  }

  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div {
    height: 100%;
  }

  &--full-height {
    .slider__inner {
      width: 100vw;
      height: 100vh;

      @include breakpoint(small only) {
        height: 66vh;
      }
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    @include breakpoint(small only) {
      height: 66vh;
    }

    picture {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    iframe,
    video {
      position: absolute;
    }
  }

  &__caption {
    position: absolute;
    top: 40%;
    left: 40px;
    right: 40px;
    color: #fff;
    z-index: 2;
    max-width: 720px;
    width: 100%;
    margin: auto;

    @include breakpoint(small only) {
      max-width: none;
      width: auto;
    }

    h2 {
      @include slider-caption();
      color: get-color("white");
      margin-bottom: 24px;

      @include breakpoint(small only) {
        margin-bottom: 10px;
      }
    }

    p {
      @include subheading();
      font-size: 14px;
      line-height: 19px;
      color: get-color("white");
    }

    .button {
      margin-top: 14px;

      @include breakpoint(small only) {
        margin-top: 5px;
      }
    }
  }

  &__scroll {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    z-index: 1000;

    @media screen and (max-width: $offcanvas-breakpoint) {
      bottom: 60px;
    }

    button {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      text-indent: -123456px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      background: url("img/arrow-thin-down-white.svg") no-repeat;
      background-size: 100% 100%;

      &:hover,
      &:active {
        transform: translateY(5px);
      }
    }
  }

  .slick-arrow {
		position: absolute;
		z-index: 100;
		top: 50%;
		margin: 0;
		transform: translateY(-50%);
    width: 21px;
		height: 21px;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
		text-indent: -12345px;
		background: no-repeat;
		background-size: 27px 21px;

    @include breakpoint(small only) {
      top: 70%;
    }
		
		&.slick-prev {
      content: '';
			left: 40px;
      background-image: url('img/carousel-prev-white.svg');
			background-position: left center;

      @include breakpoint(small only) {
        left: calc(50% - 40px);
      }
		}
		
		&.slick-next {
      content: '';
			right: 60px;
      background-image: url('img/carousel-next-white.svg');
			background-position: right center;

      @include breakpoint(small only) {
        right: calc(50% - 40px);
      }
		}
	}
}
